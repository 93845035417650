import React, { useEffect } from 'react'
import { useGetBanner2Mutation } from '@services/AllApi'
import { useDispatch, useSelector } from 'react-redux'
import { setLoader } from '@Slice'

export default function Bannertwo() {
  const lang = useSelector((state) => state.HomeSlice.lang)
  const dispatch = useDispatch()
  const [getBanner2, { data: getBanner2Data, isLoading: getBanner2Loading, isSuccess: getBanner2Suc }] = useGetBanner2Mutation()
  const bannerData = {
    method: "get_bottom_banners"
  }
  useEffect(() => {
    getBanner2(bannerData)
  }, [])

  useEffect(() => {
    getBanner2Loading && dispatch(setLoader(getBanner2Loading))
  }, [getBanner2Loading])

  useEffect(() => {
    dispatch(setLoader(!getBanner2Suc))
  }, [getBanner2Suc])


  const textLangKey = `text_${lang}`
  const titleLangKey = `title_${lang}`

  return (
    <div>
      <div className='lg:container mx-auto my-[60px]'>
        {
          getBanner2Data?.result.length > 0 &&
          getBanner2Data.result.map(item => (
            <div key={item.id} className="lg:rounded-lg bg-no-repeat bg-cover bg-center lg:bg-right" style={{ backgroundImage: `url(https://backend.morobolsin.uz/${item.bg_image})` }}>
              <div className="p-6">
                <div className="bg-white lg:w-[30%] rounded-r-[80px] rounded-l-[14px] py-10 px-7 hidden lg:block">
                  <h2 className='text-[30px] lightJec  leading-[37px]'>{item[titleLangKey]}</h2>
                  <button className='bg-white leading-[130px] text-[86px] mt-[88px] rounded-[30px] whitespace-nowrap text-[#82F50F]'>{item.value}</button>
                  <p className='text-[16px] text-[#595959] leading-6'>{item[textLangKey]} </p>
                </div>
                <div className='block lg:hidden'>
                  <h1 className='text-[#fff] text-[20px] font-semibold'>{item[titleLangKey]}</h1>
                  <h1 className=' mb-[98px] text-[#F5610F] text-[40px]'>
                    {item.value}
                  </h1>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}
