import React, {} from 'react'
import "./Loader.css"

function Loader() {
    
    return (
        <div className='loader-container'>
            <div className="loader">
            </div>
        </div>
    )
}

export default Loader