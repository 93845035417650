import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function Description(props) {
    const lang = useSelector((state) => state.HomeSlice.lang);
    const titleLangKey = `title_${lang}`;
    const descriptionLangKey = `description_${lang}`;

    // State to track the selected title
    const [selectedTitleIndex, setSelectedTitleIndex] = useState(null);

    useEffect(() => {
        setSelectedTitleIndex(props.getProductDetailData?.result[0]?.descriptions[0]?.id)
    }, [props])

    return (
        <div className='flex flex-col' >
            <div className='flex items-center justify-between gap-[10px]'>
                {props.getProductDetailSuc && props.getProductDetailData?.status === true &&
                    props.getProductDetailData.result[0]?.descriptions.map((item, index) => (
                        <p
                            key={index}
                            onClick={() => setSelectedTitleIndex(item.id)}
                            className={`py-[12px] w-full flex items-center justify-center text-[14px] cursor-pointer text-[#575B5F] rounded-t-[8px]  ${selectedTitleIndex === item.id ? 'bg-[#F5F5F5]' : 'bg-[#FBFBFB]'}`}
                        >
                            {item[titleLangKey]}
                        </p>
                    ))}
            </div>
            <div className='bg-[#F5F5F5] rounded-b-[8px] py-[32px] flex items-center gap-[16px] px-[16px]'>
                {props.getProductDetailSuc && props.getProductDetailData?.status === true &&
                    props.getProductDetailData.result[0]?.descriptions.map((item, index) => {
                        if (item.id === selectedTitleIndex) { // Only show the description of the selected title
                            const descriptionText = item[descriptionLangKey];
                            const containsHtml = /<\/?[a-z][\s\S]*>/i.test(descriptionText);

                            return (
                                <div
                                    key={index}
                                    className="flex items-center flex-col justify-center text-[14px] cursor-pointer text-[#575B5F] rounded-t-[8px] whitespace-pre-line"
                                >
                                    {containsHtml ? (
                                        <div
                                            className="w-full"
                                            dangerouslySetInnerHTML={{
                                                __html: descriptionText
                                                    .replace(/\r\n/g, '<br/>')
                                                    .replace(/<ul>/g, '<ul class="list-disc pl-5">'),
                                            }}
                                        />
                                    ) : (
                                        <p>{descriptionText.replace(/\r\n/g, '\n')}</p>
                                    )}
                                </div>
                            );
                        }
                        return null; // Hide non-selected descriptions
                    })}
            </div>
        </div >
    );
}

export default Description;
