import React, { useEffect } from 'react'
import { useGetAllProductsMutation } from '@services/AllApi';
import OurproductMap from './OurproductMap';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from '@Slice';

export default function OurProduct() {
    const dispatch = useDispatch()
    const langData = useSelector((state) => state.HomeSlice.langData)
    const [getAllProducts, { data: getAllProductsData, isLoading: getAllProductsLoading, isSuccess: getAllProductsSuc }] = useGetAllProductsMutation();

    const productsBody = {
        method: "get_all_products"
    };

    useEffect(() => {
        getAllProducts(productsBody);
    }, []);

    useEffect(() => {
        getAllProductsLoading && dispatch(setLoader(getAllProductsLoading))
    }, [getAllProductsLoading])


    useEffect(() => {
        getAllProductsSuc && dispatch(setLoader(!getAllProductsSuc));
    }, [getAllProductsSuc]);


    return (
        <div className="container mx-auto">
            <h2 className='text-[30px] mb-[24px] lg:mb-5'>{langData.allProducts}</h2>

            <div className="grid grid-cols-2 lg:grid-cols-4 gap-2">
                {
                    getAllProductsData?.result.products.length > 0 &&
                    getAllProductsData?.result.products.map(item => (
                        <OurproductMap key={item.id} item={item} />
                    ))
                }
            </div>
            {
                getAllProductsData?.result.length > 10 &&
                <button className='w-full py-[18px] bg-[#EBEBEB] rounded-xl text-[16px] font-medium leading-6 mt-5'> {langData.seeOneMore}</button>
            }
        </div>
    )
}
