import React, { useEffect, useMemo, useState } from 'react'
import support from '@assets/imgs/support.png'
import { icons } from '@utilits/icons'
import { usePostMassageSupportMutation } from '@services/AllApi'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux'


export default function Support() {
  const langData = useSelector((state) => state.HomeSlice.langData)
  const [phone, setPhone] = useState("")
  const [name, setName] = useState("")
  const massageSendSuc = () => toast.success('Ma`lumot jonatildi');

  const [postMassageSupport, { data: postMassageSupportData, isLoading: postMassageSupportLoading, isSuccess: postMassageSupportSuc }] = usePostMassageSupportMutation()

  const massageData = {
    method: "support",
    params: {
      name: name,
      phone: phone
    }
  }

  const massageFnc = (e) => {
    e.preventDefault()
    if (phone.length > 0 && name.length > 0) {
      postMassageSupport(massageData)
      setPhone("")
      setName("")
    }
  }
  useEffect(() => {
    postMassageSupportSuc && massageSendSuc()

  }, [postMassageSupportData, postMassageSupportSuc])




  return (
    <div className='lg:container mt-[100px] mx-auto'>
      <div className="flex gap-9 justify-between flex-col lg:flex-row">
        <img src={support} alt="" className='lg:rounded-3xl w-full lg:w-[40%]' />
        <div className="py-[24px] px-[16px] lg:p-[40px] w-[90%] mt-[-50%] lg:w-full lg:mt-0 mx-auto shadow-[0px_4px_14px_0px_#D3D3D359] flex flex-col justify-between bg-white lg:rounded-3xl lg:border-[12px] border-gray-100">
          <div className="">
            <h3 className=' text-[24px] lg:text-[40px] font-medium'>{langData.supportInfo}</h3>
            <p className='text-[14px] lg:text-[18px] mt-[16px] text-[#737373]'>{langData.supportInfoText}</p>
          </div>
          <form onSubmit={(e) => massageFnc(e)} action="" className='lg:w-[70%] w-full gap-10 grid'>
            <input value={phone} onChange={(e) => setPhone(e.target.value)} type="text" placeholder='+998 97 123 45 56' className='border-b-[2px] w-full border-black py-[10px] text-[24px] lg:text-[30px] outline-none' />
            <input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder={langData.yourName} className='border-b-[2px] w-full border-black py-[10px]  text-[24px] lg:text-[30px] outline-none' />
            <div className="flex items-center">
              <button type="submit" className='bg-[#1A213D] text-white py-[14px] px-6 rounded-[30px]'>{langData.sendMassage}</button>
              <button className='border-[1px] border-gray-500 rounded-full p-2'>{icons.arrowup}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
