import React, { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import arrLeft from "@assets/icons/arrLeft.svg";
import { useDispatch } from 'react-redux';
import { setLoader } from '@Slice';

function ImageSwiperSm(props) {
    const dispatch = useDispatch();
    const [mainImage, setMainImage] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleThumbnailClick = (path, index) => {
        setMainImage(`https://backend.morobolsin.uz/${path}`);
        setCurrentIndex(index);
    };

    const handlePrevClick = () => {
        if (currentIndex > 0) {
            const newIndex = currentIndex - 1;
            setMainImage(`https://backend.morobolsin.uz/${props.getProductDetailData.result[0]?.files[newIndex].path}`);
            setCurrentIndex(newIndex);
        }
    };

    const handleNextClick = () => {
        if (currentIndex < props.getProductDetailData.result[0]?.files.length - 1) {
            const newIndex = currentIndex + 1;
            setMainImage(`https://backend.morobolsin.uz/${props.getProductDetailData.result[0]?.files[newIndex].path}`);
            setCurrentIndex(newIndex);
        }
    };

    // Swipe handlers for mobile gestures
    const swipeHandlers = useSwipeable({
        onSwipedLeft: handleNextClick,
        onSwipedRight: handlePrevClick,
        preventDefaultTouchmoveEvent: true,
        trackTouch: true
    });

    useEffect(() => {
        if (props.getProductDetailData?.status === true && props.getProductDetailSuc) {
            setMainImage(`https://backend.morobolsin.uz/${props.getProductDetailData.result[0]?.files[0]?.path}`);
            setCurrentIndex(0);  // Reset index when data changes
        }
        if (props.getProductDetailSuc) {
            dispatch(setLoader(true));
        }
    }, [props.getProductDetailData, props.getProductDetailSuc]);

    return (
        <div className="flex  lg:hidden">
            {/* Thumbnails Grid Container */}
            <div className="flex items-center overflow-x-scroll gap-[10px]">
                {props.getProductDetailData?.status === true && props.getProductDetailSuc && props.getProductDetailData.result[0]?.files.map((item, index) => (
                    <img
                        key={index}
                        src={`https://backend.morobolsin.uz/${item.path}`}
                        alt={`thumbnail-${index}`}
                        className={`cursor-pointer w-full  rounded-[8px]`}
                        onClick={() => handleThumbnailClick(item.path, index)}
                    />
                ))}
            </div>

            {/* Main Image Container with Swipe */}
          
        </div>
    );
}

export default ImageSwiperSm;
