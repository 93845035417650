import React from 'react';
import leftArrWhite from "@assets/icons/arrow-right.svg";
import arrowDown from "@assets/icons/arrow-down.svg";
import { useDispatch, useSelector } from 'react-redux';
import { setQuantity } from '@Slice';



function EmbroyderyStep4({ lineNum, textLine1, textLine2, selectedFont1, selectedFont2, color1, color2, productName }) {
    const selectedSize = useSelector(state => state.HomeSlice.selectedSize)
    const quantity = useSelector(state => state.HomeSlice.quantity)
    const dispatch = useDispatch()
    const langData = useSelector(state => state.HomeSlice.langData)


    return (
        <div className='flex flex-col'>
            <h1 className='font-medium text-3xl mb-3'>
                {langData.total_amount}
            </h1>

            <div className='bg-embroideryBgImg bg-cover w-3/4 h-[50vh] bg-center'>
                <div className='h-[25px] max-w-[163px] text-[24px] ml-[31%] mt-[35%] flex items-center justify-center'>
                    <p style={{ fontFamily: selectedFont1, color: color1 }}>{textLine1}</p>
                </div>
                {lineNum > 1 && (
                    <div className='h-[25px] max-w-[163px] ml-[31%] mt-[5px] flex items-center justify-center text-[24px]'>
                        <p style={{ fontFamily: selectedFont2, color: color2 }}>{textLine2}</p>
                    </div>
                )}
            </div>
            <h2 className='mt-[32px] text-[24px] font-semibold'>
                {productName}
            </h2>
            <p className='mt-[24px] text-[24px]'>{langData.Size}: {selectedSize} </p>
            <div className='mt-[24px] flex items-center justify-between w-3/4'>
                <div className='flex items-center gap-[12px] '>
                    <p className='text-[24px]'>{langData.Quantity}:</p>
                    <input value={quantity} onChange={(e) => dispatch(setQuantity(e.target.value))} className='border border-[#B9BBBE] w-[110px] py-[19px] px-[16px] rounded-[4px]' type="text" placeholder='1' />
                </div>
                <h3 className='text-[24px] font-semibold'>750 000 UZS</h3>
            </div>

            <div className='h-[1px] w-full bg-gray-400 my-[32px]'>
            </div>
            <p className='text-[20px] font-semibold mb-[24px] flex items-center justify-between w-1/2'>+ {langData.Embroidery_text} <span>{lineNum > 1 ? "60 000" : "30 000"} {langData.sum}</span></p>
            <div className='w-1/2 flex flex-col gap-[16px]'>
                <h3 className='flex items-center justify-between text-[20px] font-semibold'>1-qator: <p className='font-semibold'>{textLine1}</p></h3>
                <h3 className='flex items-center justify-between text-[20px] '>{langData.shrift}: <p className=''>{selectedFont1}</p></h3>
                <h3 className='flex items-center justify-between text-[20px] '>{langData.color}:  <p className='flex items-center gap-2'> <div style={{ backgroundColor: color1 }} className={` w-[24px] h-[24px] border border-[#DCDDDF] rounded-full`}></div>{color1}</p></h3>
            </div>
            <div className='h-[1px] w-full bg-gray-400 my-[32px]'>
            </div>
            {
                lineNum > 1 &&
                <div className='w-1/2 flex flex-col gap-[16px]'>
                    <h3 className='flex items-center justify-between text-[20px] font-semibold'>2-qator: <p className='font-semibold'>{textLine2}</p></h3>
                    <h3 className='flex items-center justify-between text-[20px] '>{langData.shrift}: <p className=''>{selectedFont2}</p></h3>
                    <h3 className='flex items-center justify-between text-[20px] '>{langData.color}:  <p className='flex items-center gap-2'> <div style={{ backgroundColor: color2 }} className={` w-[24px] h-[24px] border border-[#DCDDDF] rounded-full`}></div>{color2}</p></h3>
                </div>
            }

        </div>
    );
}

export default EmbroyderyStep4;
