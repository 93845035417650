import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import env from "react-dotenv";
const baseQuery = fetchBaseQuery({
    baseUrl: "https://backend.morobolsin.uz",
    prepareHeaders: (headers) => {
        const token = process.env.REACT_APP_API_KEY || localStorage.getItem("apiToken");
        if (token) {
            headers.set("Authorization", `Bearer ${token}`);
        }
        headers.set("accept", "application/json");
        return headers;
    },
});




const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        const refreshResult = await baseQuery(
            {
                url: "/api/update-token",
                method: "POST",
                body: {
                    token: process.env.REACT_APP_API_KEY
                },
                headers: {
                    accept: 'application/json',
                }
            },
            api,
            extraOptions
        );

        if (refreshResult.data) {
            const newToken = refreshResult.result.access_token;
            // Store new token in localStorage
            localStorage.setItem("apiToken", newToken);

            result = await baseQuery(args, api, extraOptions);
        }
    }

    return result;
};

export const AllApi = createApi({
    reducerPath: "api",
    baseQuery: baseQueryWithReauth,

    endpoints: (builder) => ({
        getAllCategory: builder.mutation({
            query: (categories) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: categories,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`
                }
            }),
        }),
        getOneCategory: builder.mutation({
            query: (category) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: category,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`
                }
            }),
        }),
        getBanner: builder.mutation({
            query: (banner) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: banner,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`
                }
            }),
        }),
        getBanner2: builder.mutation({
            query: (banner) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: banner,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`
                }
            }),
        }),
        getPartners: builder.mutation({
            query: (partners) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: partners,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`
                }
            }),
        }),
        postMassageSupport: builder.mutation({
            query: (massage) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: massage,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`
                }
            }),
        }),

        getAllProducts: builder.mutation({
            query: (product) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: product,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`
                }
            }),
        }),
        register: builder.mutation({
            query: (user) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: user,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`
                }
            }),
        }),
        verificationOtp: builder.mutation({
            query: (otp) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: otp,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`
                }
            }),
        }),
        resendOtp: builder.mutation({
            query: (otp) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: otp,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`
                }
            }),
        }),
        loginClient: builder.mutation({
            query: (client) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: client,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`
                }
            }),
        }),
        categoryDetails: builder.mutation({
            query: (categoryId) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: categoryId,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`
                }
            }),
        }),
        getAllColors: builder.mutation({
            query: (colors) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: colors,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`
                }
            }),
        }),
        getProductDetail: builder.mutation({
            query: (detailBody) => ({
                url: '/api/jsonrpc',
                method: "POST",
                body: detailBody,
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`
                }
            }),
        }),
    })

});

export const {
    useGetAllCategoryMutation, useGetOneCategoryMutation,
    useGetBannerMutation, useGetBanner2Mutation,
    useGetPartnersMutation, usePostMassageSupportMutation,
    useGetAllProductsMutation, useRegisterMutation,
    useVerificationOtpMutation, useResendOtpMutation,
    useLoginClientMutation, useCategoryDetailsMutation,
    useGetAllColorsMutation, useGetProductDetailMutation
} = AllApi;
