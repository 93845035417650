import React from 'react'
import arrDown from "@assets/icons/arrow-down.svg"
import { useSelector } from 'react-redux'

function FiltersSm() {
    const langData = useSelector(state => state.HomeSlice.langData)
    return (
        <div className='flex  flex-col gap-[6px] mb-[37px]'>
            <div className='bg-[#F5F5F5] py-[11px] px-[20px] rounded-lg'>
                <p>{langData.Filters}</p>
            </div>
            <div className='flex items-center justify-between py-[19px] px-[16px] border border-[#B9BBBE] rounded-lg'>
                <p>{langData.Product_types}</p>
                <img src={arrDown} alt="" />
            </div>
            <div className='flex items-center justify-between py-[19px] px-[16px] border border-[#B9BBBE] rounded-lg'>
                <p>{langData.Filter_by_price}</p>
                <img src={arrDown} alt="" />
            </div>
            <div className='flex items-center justify-between py-[19px] px-[16px] border border-[#B9BBBE] rounded-lg'>
                <p>{langData.Colors}</p>
                <img src={arrDown} alt="" />
            </div>
        </div>
    )
}

export default FiltersSm