import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
    activresupmenu: false,
    opensearch: false,
    loader: false,
    lang: "ru",
    langData: {},
    quantity: 1,
    selectedSize: null
}

export const slice = createSlice({
    name: 'HomeSlice',
    initialState,
    reducers: {
        setActivesupmenu: (state, action) => {
            state.activresupmenu = action.payload
        },
        setOpensearch: (state, action) => {
            state.opensearch = action.payload
        },
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        setLang: (state, action) => {
            state.lang = action.payload
        },
        setLangData: (state, action) => {
            state.langData = action.payload
        },
        setQuantity: (state, action) => {
            state.quantity = action.payload
        },
        setSelectedSize: (state, action) => {
            state.selectedSize = action.payload
        }
    }
})

export const { setActivesupmenu, setLang, setLangData, setLoader, setOpensearch, setQuantity, setSelectedSize } = slice.actions

export default slice.reducer 