import React from 'react';
import leftArrWhite from "@assets/icons/arrow-right.svg";
import arrowDown from "@assets/icons/arrow-down.svg";
import { useSelector } from 'react-redux';

// Expanded list of color options
const ColorOptions = [
    "white",
    "black",
    "green",
    "gray",
    "red",
    "yellow",
    "blue",
    "orange"
];

function EmbroyderyStep3({ setSteps, lineNum, textLine1, textLine2, setTextLine1, setTextLine2, selectedFont1, selectedFont2, color1, color2, setColor1, setColor2 }) {
    const langData = useSelector(state => state.HomeSlice.langData)
    return (
        <div className='flex flex-col'>
            <h1 className='font-medium text-3xl'>
                {langData.Write_text_and_choose_color}
            </h1>
            <p className='text-xl my-4 text-[#84888E]'>
                {langData.Youve_chosen_a_Color}
            </p>
            <div className='bg-embroideryBgImg bg-cover w-3/4 h-[50vh] bg-center'>
                <div className='h-[25px] max-w-[163px] text-[24px] ml-[31%] mt-[35%] flex items-center justify-center'>
                    <p style={{ fontFamily: selectedFont1, color: color1 }}>{textLine1}</p>
                </div>
                {lineNum > 1 && (
                    <div className='h-[25px] max-w-[163px] ml-[31%] mt-[5px] flex items-center justify-center text-[24px]'>
                        <p style={{ fontFamily: selectedFont2, color: color2 }}>{textLine2}</p>
                    </div>
                )}
            </div>
            <form className='flex flex-col mt-[32px]' action="">
                <div className='w-3/4'>
                    <p className='text-base text-[#84888E]'>
                        {langData.first_choose_color}
                    </p>
                    <div className='flex items-center gap-3'>
                        <input
                            autoFocus
                            className='border border-[#B9BBBE] py-[19px] px-[16px] rounded-[4px] mt-[8px] text-[16px] w-full'
                            onChange={(e) => setTextLine1(e.target.value)}
                            required
                            value={textLine1 || ''}
                            type="text"
                        />
                        <div className='flex gap-2'>
                            {ColorOptions.map(color => (
                                <div
                                    key={color}
                                    className={`${color == color1 ? "border-3" : "border"} border-gray-400  rounded-full w-[50px] h-[50px] cursor-pointer`}
                                    style={{ backgroundColor: color }}
                                    onClick={() => setColor1(color)}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                {lineNum > 1 && (
                    <div className='mt-[24px] w-3/4'>
                        <p className='text-base text-[#84888E]'>
                            {langData.second_choose_color}
                        </p>
                        <div className='flex items-center gap-3'>
                            <input
                                className='border border-[#B9BBBE] py-[19px] px-[16px] rounded-[4px] mt-[8px] text-[16px] w-full'
                                onChange={(e) => setTextLine2(e.target.value)}
                                required
                                value={textLine2 || ""}
                                type="text"
                            />
                            <div className='w-full  flex gap-2'>
                                {ColorOptions.map(color => (
                                    <div
                                        key={color}
                                        className={`${color == color2 ? "border-3" : "border"} border-gray-400 rounded-full w-[50px] h-[50px] cursor-pointer`}
                                        style={{ backgroundColor: color }}
                                        onClick={() => setColor2(color)}
                                    />
                                ))}
                            </div>
                        </div>

                    </div>
                )}
                <div className='flex items-center justify-between mt-8'>
                    <img onClick={() => setSteps(2)} className='w-9 h-9 rotate-[90deg] cursor-pointer' src={arrowDown} alt="" />
                    <button onClick={() => setSteps(4)} className='p-6 text-[#fff] flex items-center gap-6 rounded-lg self-end bg-[#1A213D]'>
                        {langData.nextButton} <img src={leftArrWhite} alt="" />
                    </button>
                </div>
            </form>
        </div>
    );
}

export default EmbroyderyStep3;
