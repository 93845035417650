import React, { useEffect, useState } from 'react';
import arrLeft from "@assets/icons/arrLeft.svg";
import { useDispatch } from 'react-redux';
import { setLoader } from '@Slice';

function ImageSwiper(props) {
    const dispatch = useDispatch();
    const [mainImage, setMainImage] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleThumbnailClick = (path, index) => {
        setMainImage(`https://backend.morobolsin.uz/${path}`);
        setCurrentIndex(index);
    };

    const handlePrevClick = () => {
        if (currentIndex > 0) {
            const newIndex = currentIndex - 1;
            setMainImage(`https://backend.morobolsin.uz/${props.getProductDetailData.result[0]?.files[newIndex].path}`);
            setCurrentIndex(newIndex);
        }
    };

    const handleNextClick = () => {
        if (currentIndex < props.getProductDetailData.result[0]?.files.length - 1) {
            const newIndex = currentIndex + 1;
            setMainImage(`https://backend.morobolsin.uz/${props.getProductDetailData.result[0]?.files[newIndex].path}`);
            setCurrentIndex(newIndex);
        }
    };

    useEffect(() => {
        if (props.getProductDetailData?.status === true && props.getProductDetailSuc) {
            setMainImage(`https://backend.morobolsin.uz/${props.getProductDetailData.result[0]?.files[0]?.path}`);
            setCurrentIndex(0);  // Reset index when data changes
        }
        if (props.getProductDetailSuc) {
            dispatch(setLoader(true));
        }
    }, [props.getProductDetailData, props.getProductDetailSuc]);

    return (
        <div className='flex'>
            {/* Thumbnails Container */}
            <div className='flex flex-col overflow-y-auto w-[20%]  h-full max-h-[80vh]'>
                {props.getProductDetailData?.status === true && props.getProductDetailSuc && props.getProductDetailData.result[0]?.files.map((item, index) => (
                    <img
                        key={index}
                        src={`https://backend.morobolsin.uz/${item.path}`}
                        alt={`thumbnail-${index}`}
                        className={`cursor-pointer w-full h-[150px] mb-[12px] rounded-[12px] ${index === currentIndex ? 'border-2 border-black' : ''}`}
                        onClick={() => handleThumbnailClick(item.path, index)}
                    />
                ))}
            </div>

            {/* Main Image Container */}
            <div className="main-image-container relative  w-[100%] h-[80vh] ml-4">
                <img className='rounded-[12px] w-full h-full object-cover' src={mainImage} alt="main" />
                <img
                    onClick={handlePrevClick}
                    className='absolute left-0 top-1/2 transform cursor-pointer -translate-y-1/2 bg-transparent ml-[23px]'
                    disabled={currentIndex === 0}
                    src={arrLeft}
                    alt="Previous"
                />
                <img
                    onClick={handleNextClick}
                    className='absolute rotate-[180deg] right-0 cursor-pointer top-1/2 transform -translate-y-1/2 bg-transparent mr-[23px]'
                    disabled={currentIndex === props.getProductDetailData?.result[0]?.files.length - 1}
                    src={arrLeft}
                    alt="Next"
                />
            </div>
        </div>
    );
}

export default ImageSwiper;
