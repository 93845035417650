import React, { useEffect, useState } from 'react';
import loginBg from "@assets/imgs/loginBg.png";
import logo from "@assets/icons/logo.svg";
import eye from "@assets/icons/eye.svg"
import logoWhite from "@assets/icons/logoWhite.svg";
import googleBg from "@assets/icons/googleBg.svg";
import appleBg from "@assets/icons/appleBg.svg";
import facebookBg from "@assets/icons/facebookBg.svg";
import { useNavigate } from 'react-router-dom';
import { useLoginClientMutation } from '@services/AllApi';
import { useSelector } from 'react-redux';

function Login() {
    const navigate = useNavigate();
    const langData = useSelector((state) => state.HomeSlice.langData)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loginClient, { data: loginCLientData, isSuccess: loginClientSuc }] = useLoginClientMutation()




    const loginClientFnc = (e) => {
        e.preventDefault()
        localStorage.setItem('clientEmail', email)
        const loginData = {
            method: "loginClient",
            params: {
                email: email,
                password: password
            }
        }

        loginClient(loginData)
    }

    useEffect(() => {
        if (loginCLientData?.status == true) {
            localStorage.setItem('clientToken', loginCLientData?.result.token)
            navigate('/')
        }
    }, [loginCLientData, loginClientSuc])

    return (
        <div style={{ backgroundImage: `url(${loginBg})` }} className='bg-cover  w-full  bg-center p-[16px] flex items-start justify-between'>
            <img onClick={() => navigate("/")} className='p-[60px] cursor-pointer' src={logo} alt="" />
            <form onSubmit={(e) => loginClientFnc(e)} className='h-[96vh] px-[24px] w-[493px] py-[40px] rounded-[16px] flex items-center justify-between flex-col bg-[white]'>
                <div className='w-full'>
                    <div className='bg-[#1A213D] flex items-center justify-center w-[70%] mx-[auto] py-[22px] rounded-[12px] px-[46px]'>
                        <img src={logoWhite} alt="" />
                    </div>
                    <h1 className='text-[24px] extraBoldJeko text-center mt-[29px] mb-[9px]'>{langData.Login}</h1>
                    <>
                        <input
                            className='bg-[#F6F6F6] JekoMedium w-full mt-[24px] rounded-[72px] py-[18px] px-[24px] placeholder-[#AAAAAA] text-[14px] outline-[#cecece]'
                            placeholder={langData.email}
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                        />
                        <div className='bg-[#F6F6F6]  w-full mt-[16px] rounded-[72px] py-[18px] px-[24px] placeholder-[#AAAAAA]  '>
                            <input
                                className='bg-[#F6F6F6] outline-none JekoMedium w-full   placeholder-[#AAAAAA] text-[14px] '
                                placeholder={langData.password}
                                onChange={(e) => setPassword(e.target.value)}
                                type="password"
                            />
                            <img src="" alt="" />
                        </div>
                    </>
                    <p className='text-sm text-[red] mt-1'>{loginCLientData?.error.message}</p>
                </div>
                <div className='w-full mt-[24px]'>
                    <button className='bg-[#D2D3D8]  JekoMedium rounded-[72px] w-full text-[16px] text-[#fff] py-[15px] '>{langData.login}</button>
                    <button onClick={() => navigate('/register')} className='JekoMedium rounded-[72px] w-full text-[16px] border !border-[black] py-[15px] mt-[12px]'>{langData.register}</button>
                    <div className='flex items-center  gap-[10px] mt-[30px]'>
                        <div className='bg-[#f6f6f6] w-full h-[1px]'></div>
                        <p className='w-full text-[12px] whitespace-nowrap text-[#aaaaaa]'>
                            {langData.orThrowThis}
                        </p>
                        <div className='bg-[#f6f6f6] w-full h-[1px]'></div>
                    </div>
                    <div className='mt-[24px] justify-center flex items-center gap-[30px]'>
                        <img className='cursor-pointer' src={googleBg} alt="" />
                        <img className='cursor-pointer' src={appleBg} alt="" />
                        <img className='cursor-pointer' src={facebookBg} alt="" />
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Login;
