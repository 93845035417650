import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from '@pages/home';
import Navbar from '@components/navbar';
import BlackTop from '@components/blackTop';
import Search from '@pages/search';
import Loader from '@components/loader';
import Register from '@pages/register';
import Login from '@pages/login';
import Footer from '@components/footer';
import Otp from '@pages/otp';
import Product from '@pages/products';
import ProductDetail from '@pages/productDetail';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';


function App() {
  const opensearch = useSelector((state) => state.HomeSlice.opensearch)
  const loader = useSelector((state) => state.HomeSlice.loader)
  console.log(process.env.REACT_APP_API_KEY)

  return (
    <div className={`App relative`}>
      <BlackTop />
      <Navbar />
      {opensearch && <Search />}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/product/:id' element={<Product />} />
        <Route path='/register' element={<Register />} />
        <Route path='/login' element={<Login />} />
        <Route path='/otp' element={<Otp />} />
        <Route path='/productDetail/:productId' element={<ProductDetail />} />
      </Routes>
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
      {loader && <Loader />}
    </div>
  );
}

export default App;
