import React, { useEffect } from 'react'
import foiz from "@assets/icons/foiz.svg"
import arrowup from "@assets/icons/arrowup.svg"
import banner from "@assets/imgs/banner.png"
import { useGetBannerMutation } from '@services/AllApi'
import { useDispatch, useSelector } from 'react-redux'
import { setLoader } from '@Slice'
import { useNavigate } from 'react-router-dom'

function Banner() {
    const langData = useSelector((state) => state.HomeSlice.langData)
    const lang = useSelector((state) => state.HomeSlice.lang)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [getBanner, { data: getBannerData, isLoading: getBannerLoading, isSuccess: getBannerSuc }] = useGetBannerMutation()
    const bannerData = {
        method: "get_banners"
    }
    useEffect(() => {
        getBanner(bannerData)
    }, [])


    useEffect(() => {
        getBannerLoading && dispatch(setLoader(getBannerLoading))
    }, [getBannerLoading])


    useEffect(() => {
        getBannerSuc && dispatch(setLoader(!getBannerSuc))
    }, [getBannerSuc])


    const titleLangKey = `title_${lang}`;


    return (
        <div className='lg:container mx-auto'>
            {getBannerData?.result.length > 0 &&
                getBannerData.result.map(item => (
                    <div key={item.id} className="lg:rounded-lg bg-no-repeat bg-cover lg:bg-right bg-center min-h-[700px] relative w-full" style={{ backgroundImage: `url(https://backend.morobolsin.uz/${item.bg_image})` }}>
                        <div className="lg:ml-[80px] py-20 px-[15px]">
                            <button className='bg-[#82F50F] flex items-center midiumJeko  gap-2 text-[16px] lg:text-[32px] text-white rounded-lg py-[8px] px-[12px]  lg:py-[10px] lg:px-[24px] mb-[28px]'><img src={foiz} alt="" />{item.value} {langData.sale}</button>
                            <h2 className='text-[24px] lg:text-[40px] lightJecko  lg:w-[40%] lg:leading-[50px]'>{item[titleLangKey]}</h2>
                            <div onClick={() => navigate("/product/1")} className="flex items-center absolute bottom-[70px] lg:bottom-[101px] ">
                                <button className='py-[14px]  text-[14px] lg:text-[16px] px-[36px] bg-white rounded-[30px] whitespace-nowrap'>{langData.startBuy}</button>
                                <button className='bg-[#1A213D] p-1 mr-5 rounded-[50%]'><img src={arrowup} alt="" /></button>
                                <p className='text-[12px] text-[#595959] hidden lg:flex  font-light w-[30%]'>{langData.collection}</p>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default Banner