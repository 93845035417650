import React from 'react'
import Banner from '@components/banner'
import Category from '@components/category'
import OurWorks from '@components/ourWorks'
import TrendProducts from '@components/trendProducts'
import Order from '@components/ourorder'
import Ourproduct from '@components/ourProduct'
import Bannertwo from '@components/banner2'
import Partners from '@components/partners'
import Support from '@components/support'
import Instagram from '@components/instagram'
import Footer from '@components/footer'

function Home() {
  return (
    <div>
      <Banner />
      <Category />
      {/* <OurWorks /> */}
      <TrendProducts />
      <Order />
      <Ourproduct />
      <Bannertwo />
      <Partners />
      <Support />
      <Instagram />
      {/* <Footer/> */}
    </div>
  )
}

export default Home