import React from 'react'
import kitel from "@assets/imgs/kitel.png"
import fartuk from "@assets/imgs/fartuk.png"
import pant from "@assets/imgs/pant.png"
import shoes from "@assets/imgs/shoes.png"
import hat from "@assets/imgs/hat.png"
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

function Category() {
  const langData = useSelector((state) => state.HomeSlice.langData)
  const navigate = useNavigate()
  const category = [
    {
      text: langData.chefJacket,
      img: kitel,
      id: 1
    },
    {
      text: langData.aprons,
      img: fartuk,
      id: 6
    },
    {
      text: langData.trousers,
      img: pant,
      id: 37
    },
    {
      text: langData.shoes,
      img: shoes,
      id: 8
    },
    {
      text: langData.accessories,
      img: hat,
      id: 40
    },
  ]

  return (
    <div className='container mx-auto mt-[50px]  '>
      <div className='flex items-center justify-between mb-[17px]'>
        <h1 className='text-[24px] lg:text-[30px]'>
          {langData.categories}
        </h1>
        {/* <p className='text-[16px] cursor-pointer'>{langData.seeAll}</p> */}
      </div>
      <div className='flex items-center justify-between flex-col lg:flex-row gap-[20px]'>
        {category.map((item, index) => (
          <div onClick={() => navigate(`/product/${item.id}`)} key={index} className='w-full cursor-pointer'>
            <div className='bg-[#F5F5F5] h-[8px] rounded-t-[12px] mb-[2px] w-[90%] mx-auto'>
            </div>
            <div className='bg-[#F5F5F5] w-full h-[166px] rounded-[12px] p-[20px] relative' >
              <p className='text-[18px] font-light'>{item.text}</p>
              <img className='absolute bottom-0 right-[5px] ' src={item.img} alt="" />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Category