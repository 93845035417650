import { configureStore } from '@reduxjs/toolkit'
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query'
import { AllApi } from '../services/AllApi'
import HomeSlice from '../Slice'

export const store = configureStore({
    reducer: {
        [AllApi.reducerPath]: AllApi.reducer,
        HomeSlice: HomeSlice
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(AllApi.middleware),
})
setupListeners(store.dispatch)