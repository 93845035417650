import React, { useState } from 'react'
import arrowDown from "@assets/icons/arrow-down.svg"
import check from "@assets/icons/check.svg"
import { useDispatch, useSelector } from 'react-redux';
import { setQuantity, setSelectedSize } from '@Slice';

function SelectNumber(props) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [quantityOrder, setQuantityOrder] = useState(false)
    const selectedSize = useSelector(state => state.HomeSlice.selectedSize)
    const quantity = useSelector(state => state.HomeSlice.quantity)
    const dispatch = useDispatch()
    const langData = useSelector(state => state.HomeSlice.langData)

    const toggleDropdown = () => {
        setIsDropdownOpen(prevState => !prevState);
    };

    return (
        <div className='my-[32px]'>
            <div className='flex items-center justify-between gap-[30px]'>
                <p onClick={() => setQuantityOrder(false)} className={`${quantityOrder ? 'bg-[#FBFBFB]' : "bg-[#F5F5F5]"} py-[12px] w-full flex items-center justify-center text-[12px] lg:text-[14px] cursor-pointer text-[#575B5F] rounded-t-[8px]`}>{langData.One_size_order}</p>
                <p onClick={() => setQuantityOrder(true)} className={`${quantityOrder ? 'bg-[#F5F5F5]' : "bg-[#FBFBFB]"} py-[12px] w-full flex items-center justify-center text-[12px] lg:text-[14px] cursor-pointer text-[#575B5F] rounded-t-[8px]`}>{langData.Multi_size_order}</p>
            </div>
            <div className='bg-[#F5F5F5] rounded-b-[8px] py-[32px] flex items-center gap-[16px] px-[16px]'>
                {
                    quantityOrder ?
                        <div className='w-full'>
                            <div className='flex items-start justify-between'>
                                <label className='w-full text-[12px] lg:text-[16px]' htmlFor="size-select">
                                    {langData.Size}
                                </label>
                                <label className='w-full text-[12px] lg:text-[16px]' htmlFor="size-select">
                                    {langData.Quantity}
                                </label>
                            </div>
                            {props.getProductDetailData?.status === true && props.getProductDetailData.result[0].sizes.map((item, index) => (
                                <div className='flex items-center justify-between my-[8px]'>
                                    <p
                                        key={index}
                                        className='text-[16px] text-[#575B5F] w-full px-[16px] py-[19px]'
                                    >
                                        {item.value}
                                    </p>
                                    <input
                                        type="text"
                                        className='w-full rounded-[4px]  py-[19px] px-[16px] border border-[#B9BBBE] text-[16px] text-[#2C2D30] outline-none'
                                        placeholder='0'
                                        onChange={(e) => {
                                            let value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters

                                            e.target.value = value;
                                        }}

                                    />
                                </div>
                            ))}
                        </div>
                        :
                        <>
                            <label onMouseLeave={() => setIsDropdownOpen(false)} className=' relative text-[12px] lg:text-[14px] w-full text-[#96999E] dropdown-container' htmlFor="size-select">
                                {langData.Choose_a_size}
                                <div
                                    className='w-full  cursor-pointer flex items-center justify-between h-[64px] mt-[8px] rounded-[4px] py-[15px] lg:py-[19px] px-[16px] border border-[#B9BBBE] text-[14px] lg:text-[16px] text-[#2C2D30] outline-none bg-[#fff]'
                                    onClick={toggleDropdown}

                                >
                                    {selectedSize ? selectedSize : langData.Size}  <img className='w-[15px] lg:w-[24px]' src={arrowDown} alt="" />
                                </div>
                                {isDropdownOpen && (
                                    <div className='absolute bg-[#fff] border border-[#B9BBBE] p-[16px] rounded-[4px] w-full'>
                                        <ul className='text-[14px] lg:text-[16px]'>
                                            {props.getProductDetailData?.status === true && props.getProductDetailData.result[0].sizes.map((item, index) => (
                                                <li
                                                    key={index}
                                                    className={`py-[16px]  flex items-center justify-between cursor-pointer ${index !== props.getProductDetailData.result[0].sizes.length - 1 ? 'border-b border-[#EDEEEF]' : ''}`}
                                                    onClick={() => dispatch(setSelectedSize(item.value))}
                                                >
                                                    {item.value}
                                                    {selectedSize === item.value && <img src={check} alt="Selected" />}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </label>
                            <label className='text-[12px] lg:text-[14px] w-full text-[#96999E]' htmlFor="">
                                {langData.Quantity_product}
                                <input
                                    type="text"
                                    className='w-full rounded-[4px] mt-[8px]  py-[20px] px-[16px] border border-[#B9BBBE] text-[14px] lg:text-[16px] text-[#2C2D30] outline-none'
                                    placeholder='1'
                                    value={quantity}
                                    onChange={(e) => {
                                        let value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                                        if (value === "0") {
                                            value = ""; // Prevent '0' from being entered
                                        }
                                        e.target.value = value;
                                        dispatch(setQuantity(value))
                                    }}
                                    onBlur={(e) => {
                                        if (e.target.value === "") {
                                            e.target.value = "1"; // Default to '1' if the input is empty
                                        }
                                    }}
                                />
                            </label>
                        </>
                }
            </div>
        </div>
    )
}

export default SelectNumber