import React, { useEffect } from 'react'
import { icons } from '@utilits/icons'
import { useGetAllCategoryMutation } from '@services/AllApi'
import TrendProducts from '@components/trendProducts'
import { useDispatch, useSelector } from 'react-redux'
import { setOpensearch } from '@Slice'

export default function Search() {
  const dispatch = useDispatch()
  const langData = useSelector((state) => state.HomeSlice.langData)
  const lang = useSelector((state) => state.HomeSlice.lang)
  const [getAllCategory, { data: getAllCategoryData, isLoading: getAllCategoryLoading, isSuccess: getAllCategorySuc }] = useGetAllCategoryMutation();


  const categoriesBody = {
    method: "get_all_categories"
  };

  useEffect(() => {
    getAllCategory(categoriesBody);
  }, []);

  const NameLangKey = `name_${lang}`;

  return (
    <div>
      <div className='container z-50 mx-auto absolute left-[50%] translate-x-[-50%] bg-white'>
        <div className="p-5 rounded-xl shadow-2xl">
          <div className="flex justify-between items-center pb-3 border-b-[1px] border-gray-200 w-full">
            <form action="" className='items-center flex gap-2 w-[40%]'>
              <button>{icons.search}</button>
              <input type="text" className='outline-none w-full' placeholder={langData.enterNAmeProduct} />
            </form>
            <button onClick={() => dispatch(setOpensearch(false))} className='p-1 bg-gray-100 rounded-full'>{icons.close}</button>
          </div>
          <div className="grid grid-cols-4 gap-3 py-5">
            {
              getAllCategoryLoading ? '' : (
                getAllCategorySuc && getAllCategoryData?.result.length > 0 &&
                getAllCategoryData.result.map(item => (
                  item.parent_id == 0 ?
                    <button key={item.id} className='px-7 py-3 bg-[#f5f5f5] rounded-3xl'>{item[NameLangKey]}</button>
                    : ''
                ))
              )
            }
          </div>
          <TrendProducts />
        </div>
      </div>
    </div>
  )
}
